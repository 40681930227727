*, .ant-col {
  font-family: "Lora", serif;
}

.App {
  padding-top: 20px;
}

.project-preview {
  border: solid thin black;
  padding: 10px;
  width: 200px;
  text-align: left;
}


.App > .ant-row {
  margin-top: 20px;
}
